import React from 'react'

const Contact = () => {
    return (
        <div className='contact'>
            <h3>REQUEST DEMO</h3>
            <form>
                <input type="email" placeholder='YOUR EMAIL ADDRESS' name='user_email' required/>
                <br/><button type="submit" className='--btn--btn--primary'>SUBMIT</button>
            </form>
            
        </div>
    )
}

export default Contact