import React from 'react'
import videoBg from '../assets/xathra.mov'
import logo from '../assets/logo-xathra.png'
import Contact from './contact';


const Main = () => {
    return (
        <div className='main'>
            <div className='overlay'></div>
            <video src= {videoBg} autoPlay muted loop/>
            <div className='content'>
                <div className='text'>
                    <img className="logo" src={logo} alt="" />
                    <h2 className='tagline'>the future of metal casting</h2>
                    <h4>We decarbonise the metal casting industry.</h4>
                    <h4> Our patented technology is the first step toward the next generation of metal manufacturing</h4>
                    <Contact/>
                </div>
            </div>
        </div>
    )
}

export default Main